import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

const initialState = {
    cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    cartTotalCommision: 0,
    carTotalDiscount: 0,
    agencyCharge: 0
}
const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, action) {
            /*  const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
              if (itemIndex >= 0) {
                  state.cartItems[itemIndex].cartQuantity += 1;
                  toast.info(`تعداد ${action.payload.Name} در سبد خرید اضافه شد`, {
                      position: "top-center",

                  });
              } else {
                  const tempProduct = {...action.payload, cartQuantity: 1}
                  state.cartItems.push(tempProduct);
                  toast.success(`${action.payload.Name} به سبد خرید اضافه شد.`, {
                      position: "top-center"
                  });
              }*/

            let cartID = action.payload.cartID;
            const itemIndex = state.cartItems.findIndex(item => item.cartID === action.payload.cartID);
            if (itemIndex < 0) {
                cartID = state.cartItems.length + 1
                const tempProduct = {cartID: cartID, ...action.payload}
                state.cartItems.push(tempProduct);
                toast.success(`${action?.payload?.Service?.Name} به سبد خرید اضافه شد.`, {
                    position: "top-center"
                });
            } else {
                state.cartItems[itemIndex].count += 1;
                toast.success(`یک عدد ${action?.payload?.Service?.Name} اضافه شد.`, {
                    position: "top-center",
                    theme: "colored"
                });
            }

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        clearCart(state, action) {

            state.cartItems = [];
            localStorage.removeItem("cartItems");
            if (action?.payload?.withMessage) {
                toast.error("سبد خرید حدف شد", {
                    position: "top-center"
                });
            }
        },
        removeFromCart(state, action) {
            /* const nextCartItems = state.cartItems.filter(
                 cartItem => cartItem.id !== action.payload.id
             )
             state.cartItems = nextCartItems;
             localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
             toast.error(`${action.payload.Name} از سبد خرید حذف شد.`, {
                 position: "top-center"
             });*/
            let cartID = action.payload.cartID;
            const nextCartItems = state.cartItems.filter(
                item => item.cartID !== action.payload.cartID
            )
            state.cartItems = nextCartItems;
            toast.error(`${action?.payload?.Service?.Name} از سبد خرید حذف شد.`, {
                position: "top-center"
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        decreaseCart(state, action) {
            const itemIndex = state.cartItems.findIndex(
                item => item.cartID === action.payload.cartID
            )
            // alert(itemIndex)
            //   alert(state.cartItems[itemIndex].cartQuantity)
            if (state.cartItems[itemIndex].count > 1) {
                state.cartItems[itemIndex].count -= 1;
                toast.error(`یک عدد ${action?.payload?.Service?.Name} از سبد خرید کم شد.`, {
                    position: "top-center"
                });
            } else if (state.cartItems[itemIndex].count === 1) {
                const nextCartItems = state.cartItems.filter(
                    item => item.cartID !== action.payload.cartID
                )
                state.cartItems = nextCartItems;
                toast.error(`${action?.payload?.Service?.Name} از سبد خرید حذف شد.`, {
                    position: "top-center"
                });
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        getTotals(state, action) {
            let {total, quantity, commision} = state.cartItems.reduce((cartTotal, cartItem) => {
                const Price = cartItem?.Seance?.Price
                let CommisionTotal = cartItem?.Seance?.Commision * cartItem?.count
                let itemTotal = Price * cartItem?.count;
                let selectedtSeats = cartItem?.selectedSeats ? JSON.parse(JSON.stringify(cartItem.selectedSeats)) : undefined;
                if (selectedtSeats) {
                    let vipPrice = selectedtSeats.filter(p => p.type === 'vip').length * cartItem?.Seance?.vipPrice ?? 0;
                    let economyPrice = selectedtSeats.filter(p => p.type === 'economy').length * cartItem?.Seance?.economyPrice ?? 0;
                     let vipCom = selectedtSeats.filter(p => p.type === 'vip').length * cartItem?.Seance?.vipCommision ?? 0;
                    let economyCom = selectedtSeats.filter(p => p.type === 'economy').length * cartItem?.Seance?.economyCommision ?? 0;

                    itemTotal = vipPrice + economyPrice;
                    CommisionTotal = vipCom + economyCom;
                    //  console.log('ppppp ',vipPrice.toLocaleString(),economyPrice.toLocaleString())
                }
                // console.log('vvvv',cartItem.phone,JSON.parse(JSON.stringify(cartItem)))
                //  console.log('***** ',cartItem?.Seance?.vipPrice)
                cartTotal.total += itemTotal;
                cartTotal.quantity += cartItem?.count;
                cartTotal.commision += CommisionTotal;
                return cartTotal
            }, {
                total: 0,
                quantity: 0,
                commision: 0
            })
            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = total;
            state.cartTotalCommision = commision;


        },

        setAgencyCharg(state, action) {
            state.agencyCharge = action.payload;
        },
        setCartTotalDiscount(state, action) {
            state.carTotalDiscount = Number(action.payload);
        }
    }
})
export const {
    addToCart, clearCart, removeFromCart,
    decreaseCart, getTotals, setAgencyCharg, setCartTotalDiscount
} = cartSlice.actions;

export default cartSlice.reducer;