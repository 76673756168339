import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    provider: 'this is test provider'
}
const panelInfoSlice = createSlice({
    name: "panelinfo",
    initialState,
    reducers: {
        setProvder(state, action) {
            state.provider = action.payload;
        },
    }
})
export const {setProvder} = panelInfoSlice.actions;
export default panelInfoSlice.reducer;