import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    value: '',
}
export const breadCrampSlice = createSlice({
    name: 'bradCramp',
    initialState,
    reducers: {
        setBreadCramp: (state, action) => {
            //  console.log(action.payload)
            let title = '';
            switch (action.payload) {
                case '/Agencies' :
                    title = 'آژانس ها';
                    break;
                case '/AddEditAgency' :
                    title = 'ویرایش آژانس';
                    break;
                case '/Seances' :
                    title = 'سانس ها';
                    break;
                case '/Turnover' :
                    title = '';
                    break;
                case '/AddEditSeance' :
                    title = 'ویرایش سانس';
                    break;
                case '/ClosedBaskets' :
                    title = '';
                    break;
                case '/Profile' :
                    title = 'پروفایل کاربر';
                    break;
                case '/PasswordChange' :
                    title = 'تغییر رمز';
                    break;
                case '/Personel' :
                    title = 'پرسنل آژانس';
                    break;
                case '/AddEditPersonel' :
                    title = 'ویرایش پرسنل';
                    break;
                case '/Charge' :
                    title = 'شارژ';
                    break;
                case '/Notifs' :
                    title = 'اعلان ها';
                    break;
                case '/ChargeInvoice' :
                    title = '';
                    break;
                case '/AgencyOfferReport' :
                    title = '';
                    break;
                case '/AgencySellReport' :
                    title = '';
                    break;
                case '/Programs' :
                    title = 'برنامه ها';
                    break;
                case '/AddEditProgram' :
                    title = 'ویرایش برنامه';
                    break;
                case '/Service' :
                    title = 'ویترین';
                    break;
                case '/AgencyGroups' :
                    title = 'گروه آژانسی';
                    break;
                case '/Register' :
                    title = 'ثبت نام';
                    break;
                case '/TicketReport' :
                    title = 'گزارش بلیط';
                    break;
                case '/CancelReport' :
                    title = 'گزارش کنسلی';
                    break;
                case '/CancelRequests' :
                    title = 'درخواست های کنسلی';
                    break;
                case '/Search' :
                    title = 'جستجو';
                    break;
                case '/TicketInvalidate' :
                    title = 'ابطال بلیط';
                    break;
                case '/Manifest' :
                    title = '';
                    break;
                case '/Guest' :
                    title = 'میهمان ها';
                    break;
                case '/Sliders' :
                    title = 'اسلایدرها';
                    break;
                case '/AddEditSlider' :
                    title = 'ویرایش اسلایدر';
                    break;
                case '/AddEditGuest' :
                    title = 'ویرایش میهمان';
                    break;
                case '/RegisteredAgencies' :
                    title = 'آژانس های ثبت نام کرده';
                    break;
                case '/Exceptions' :
                    title = 'استثناها';
                    break;
                case '/SeanceTickets' :
                    title = '';
                    break;
                case '/GuestRequest' :
                    title = 'درخواست میهمان';
                    break;
                case '/GuestRequestView' :
                    title = '';
                    break;
                case '/Users' :
                    title = 'لیست کاربران';
                    break;


                default: {
                    if (action.payload.substr(0, 6) === '/View/') {
                        title = 'مشاهده سبد خرید'
                    } else if (action.payload.substr(0, 11) === '/ViewNotif/') {
                        title = 'مشاهد پیام'
                    }
                    title = '';
                }
            }
            state.value = title;
        }
    },
})
// Action creators are generated for each case reducer function
export const {setBreadCramp} = breadCrampSlice.actions

export default breadCrampSlice.reducer