import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    value: '',
}
export const apiServerAddressSlice = createSlice({
    name: 'apiServerAddress',
    initialState,
    reducers: {
        setApiServerAddress: (state, action) => {

            state.value = action.payload;
        }
    },
})
// Action creators are generated for each case reducer function
export const {setApiServerAddress} = apiServerAddressSlice.actions

export default apiServerAddressSlice.reducer