import {createSlice} from '@reduxjs/toolkit'

export const showHiddenSeancesSlice = createSlice({
    name: 'showHiddenSeances',
    initialState: {
        value: false
    },
    reducers: {
        setToTrue: state => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value = true
        },
        setTofalse: state => {
            state.value = false
        },
        not: (state, action) => {
            state.value = !action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const {setToTrue, setTofalse, not} = showHiddenSeancesSlice.actions

export default showHiddenSeancesSlice.reducer