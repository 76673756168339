export default async (form, id, filename = '') => {
    const formData = new FormData();
    if(form){
        Object.entries(form).forEach(([key, value]) => {

            if(value?.type?.substring(0,5)==='image')
            {
                formData.append(key,value)

            }
        });
    }
    if (form) formData.append('form', JSON.stringify(form))
    if (form?.files) {
        form.files.forEach((f) => {
            formData.append(filename, f)
        })
    }
    if (id) formData.append('id', id)
    let Token = sessionStorage.getItem('token');
    if (Token) formData.append('Token', Token);
    formData.append('location', window.location.href)
    formData.append('isClient', true)
    return formData;
}