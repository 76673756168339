import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    value: '',
}
export const siteLangSlice = createSlice({
    name: 'siteLang',
    initialState,
    reducers: {
        setSiteLang: (state, action) => {

            state.value = action.payload;
        }
    },
})
// Action creators are generated for each case reducer function
export const {setSiteLang} = siteLangSlice.actions

export default siteLangSlice.reducer