import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {GetServerAdress} from "../Api/Api";
import {GetServerAdressv3} from "../Api/Apiv3";

export const productsApi = createApi({
    reducerPath: "productsApi",
    baseQuery: fetchBaseQuery({baseUrl: GetServerAdress()}),
    endpoints: (builder) => ({

        getAllProducts: builder.query({
            query: ({cat, city}) => ({
                url: `programsNew`,
                method: "Post",
                body: {
                    Token: sessionStorage.getItem('token'),
                    cat,city,
                    consert:false
                },
                headers: []
            }),
        }),
    }),
})
export const carsForeRentApi = createApi({
    reducerPath: "carsForeRentApi",
    baseQuery: fetchBaseQuery({baseUrl: GetServerAdressv3()+"apiv3"}),
    endpoints: (builder) => ({
        getCarsForRents: builder.query({
            query: ({cat, city}) => ({
                url: `carsForRent`,
                method: "Post",
                body: {
                    Token: sessionStorage.getItem('token'),
                    cat,city
                },
                headers: []
            }),
        }),
    }),
})
export const {useGetAllProductsQuery} = productsApi;
export const {useGetCarsForRentsQuery} = carsForeRentApi;
