import {configureStore} from '@reduxjs/toolkit'
import breadCrampReducer from '../features/breadCramp'
import nameOfUserReducer from '../features/nameOfUser'
import siteLangReducer from '../features/siteLang'
import counterReducer from '../features/counter/counterSlice'
import showHiddenSeancesReducer from '../features/showHiddenSeances'
import apiServerAddressReducer from '../features/apiServerAddress'
import productReducer from "../features/productSlice";
import {productsApi,carsForeRentApi} from "../features/productsApi";
import {setupListeners} from '@reduxjs/toolkit/query';
import carReducer from "../features/cartSlice"
import panelinfoReducer from "../features/panelinfo"

const store = configureStore({

    reducer: {
        bradCramp: breadCrampReducer,
        nameOfUser: nameOfUserReducer,
        siteLang: siteLangReducer,
        counter: counterReducer,
        showHiddenSeances: showHiddenSeancesReducer,
        apiServerAddress: apiServerAddressReducer,
        products: productReducer,
        cart: carReducer,
        panelinfo: panelinfoReducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [carsForeRentApi.reducerPath]: carsForeRentApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(productsApi.middleware,carsForeRentApi.middleware),


})
//store.dispatch(productsFetch())
setupListeners(store.dispatch)
export {store}
