import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    value: '',
}
export const nameOfUserSlice = createSlice({
    name: 'nameOfUser',
    initialState,
    reducers: {
        setNameOfUser: (state, action) => {
            state.value = action.payload;
        }
    },
})
// Action creators are generated for each case reducer function
export const {setNameOfUser} = nameOfUserSlice.actions

export default nameOfUserSlice.reducer