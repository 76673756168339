import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios";
import {GetServerAdress} from "../Api/Api";

const initialState = {
    items: [],
    status: null,
    error: null
}
export const productsFetch = createAsyncThunk(
    "products/productsFetch",
    async (id = null, {rejectWithValue}) => {
        try {
            let Server = GetServerAdress();
            let Token = sessionStorage.getItem('token');
            var R = await axios.post(Server + "programs", {
                Token
            });

            /*  var B = await axios.post(Server + "baskets", {
                  Token
              });*/
            //R.data.Basket = B.data.basket;
            return R?.data;
        } catch (error) {
            return rejectWithValue("an error accured from productFetch");
        }
    }
)
const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(productsFetch.pending, (state, action) => {
            state.status = "pending"
        }),

            builder.addCase(productsFetch.fulfilled, (state, action) => {
                state.status = "success"
                state.items = action.payload
            }),
            builder.addCase(productsFetch.rejected, (state, action) => {
                state.status = "rejected"
                state.error = action.payload
            })
    }
})
export default productSlice.reducer